import React from 'react'
import { Link } from 'gatsby'
import Helmet from "react-helmet";

const TandC = () => (
	<>
		<div className="transparentContainer">
			<h1 id='inpersonjs'>JavaScript Full-Stack In-person Bootcamp</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and attendance is confirmed once the payment is made through our online booking. The tuition cost is paid in the following manner: 580€ are paid upon online registration. The remaining tuition cost is split in two equal payments: second payment is due one month before start of the program and third payment is due before program starts.</p>

				<strong>Cancellation</strong>

				<p>If you need to cancel your enrollment for any reason prior to the course starting date, we will provide you with a full refund or provide you with BCS credit to the refund value for future use towards any other course, class, or workshop, minus the inscription fees paid during the registration. This deposit cannot be redeemed towards any BCS course or workshop except for changing to the different cohort of the same course. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Withdrawal</strong>

				<p>You may withdraw within the first week of your course. You will be provided with a refund of 75% of the full course tuition in BCS credit to the refund value for future use towards any other course, class or workshop. Students who withdraw after the Friday's 18:00 of the first week of their course are liable for their full course fees. All withdraw requests must be made in writing to office@barcelonacodeschool.com. As an enrolled student, you are liable for full course fees if you decide to withdraw from your course after week 1 for any reason. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Transfers</strong>

				<p>Prior to the start of a bootcamp or during the first week of it, students may be granted one transfer (subject to approval based on class availability and existing circumstances) into a different cohort. No transfers will be granted after Friday's 18:00 of the first week of your course. All transfer requests must be made in writing to office@barcelonacodeschool.com. If your transfer to the different instance of the same course no transfer fees would be charged. For a transfer to a different course you would be charged/refunded the difference in price in BCS credit to the refund value for future use towards any other course. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

			</article>
		</div>

		<div id='gameDevBootcamp' className="transparentContainer">
			<h1>Game Development in-person Bootcamp</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and attendance is confirmed once the payment is made through our online booking. The tuition cost is paid in the following manner: 720€ are paid upon online registration. The remaining tuition cost is split in two equal payments: second payment is due one month before start of the program and third payment is due 5 days before program starts.</p>

				<strong>Cancellation</strong>

				<p>If you need to cancel your enrollment for any reason prior to the course starting date, we will provide you with a full refund or provide you with BCS credit to the refund value for future use towards any other course, class, or workshop, minus the inscription fees paid during the registration. This deposit cannot be redeemed towards any BCS course or workshop except for changing to the different cohort of the same course. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Withdrawal</strong>

				<p>You may withdraw within the first week of your course. You will be provided with a refund of 75% of the full course tuition in BCS credit to the refund value for future use towards any other course, class or workshop. Students who withdraw after the Friday's 18:00 of the first week of their course are liable for their full course fees. All withdraw requests must be made in writing to office@barcelonacodeschool.com. As an enrolled student, you are liable for full course fees if you decide to withdraw from your course after week 1 for any reason. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Transfers</strong>

				<p>Prior to the start of a bootcamp or during the first week of it, students may be granted one transfer (subject to approval based on class availability and existing circumstances) into a different cohort. No transfers will be granted after Friday's 18:00 of the first week of your course. All transfer requests must be made in writing to office@barcelonacodeschool.com. If your transfer to the different instance of the same course no transfer fees would be charged. For a transfer to a different course you would be charged/refunded the difference in price in BCS credit to the refund value for future use towards any other course. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

			</article>
		</div>

		<div className="transparentContainer">
			<h1 id='inpersonUX'>UX/UI Design in-person Bootcamp</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and attendance is confirmed once the payment is made through our online booking. The tuition cost is paid in the following manner: 580€ are paid upon online registration. The remaining tuition cost is split in two equal payments: second payment is due one month before start of the program and third payment is due before program starts.</p>

				<strong>Cancellation</strong>

				<p>If you need to cancel your enrollment for any reason prior to the course starting date, we will provide you with a full refund or provide you with BCS credit to the refund value for future use towards any other course, class, or workshop, minus the inscription fees paid during the registration. This deposit cannot be redeemed towards any BCS course or workshop except for changing to the different cohort of the same course. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Withdrawal</strong>

				<p>You may withdraw within the first week of your course. You will be provided with a refund of 75% of the full course tuition in BCS credit to the refund value for future use towards any other course, class or workshop. Students who withdraw after the Friday's 18:00 of the first week of their course are liable for their full course fees. All withdraw requests must be made in writing to office@barcelonacodeschool.com. As an enrolled student, you are liable for full course fees (including all payments of your payment plan) if you decide to withdraw from your course after week 1 for any reason. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Transfers</strong>

				<p>Prior to the start of a bootcamp or during the first week of it, students may be granted one transfer (subject to approval based on class availability and existing circumstances) into a different cohort. No transfers will be granted after Friday's 18:00 of the first week of your course. All transfer requests must be made in writing to office@barcelonacodeschool.com. If your transfer to the different instance of the same course no transfer fees would be charged. For a transfer to a different course you would be charged/refunded the difference in price in BCS credit to the refund value for future use towards any other course. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

			</article>
		</div>

		<div className="transparentContainer">
			<h1 id='onlinejs'>JavaScript Full-Stack Online Mentored Bootcamp</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and attendance is confirmed once the payment is made through our online booking. The cost of the course is one-time non-refundable payment which is done upon registration. You'll receive all the materials right after signing up.</p>

				<strong>Cancellation</strong>

				<p>If you need to cancel your enrolment for any reason prior to the course starting date, we will provide you with BCS credit to the refund value for future use towards any other course, class, or workshop. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Withdrawal</strong>

				<p>You may withdraw within the first week of your course. You will be provided with a BCS credit to the refund value for future use towards any other course, class or workshop. Students who withdraw after the Friday at 18:00 CET of the first week of their course are liable for their full course fees. All withdraw requests must be made in writing to office@barcelonacodeschool.com. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Transfers</strong>

				<p>Prior to the start of a bootcamp or during the first week of it, students may be granted one transfer (subject to approval based on class availability and existing circumstances) into a different cohort. No transfers will be granted after Friday at 18:00 CET of the first week of your course.</p>

				<p>All transfer requests must be made in writing to office@barcelonacodeschool.com. If your transfer to the different instance of the same course no transfer fees would be charged. For a transfer to a different course you would be charged the difference in price if there is any.</p>

			</article>
		</div>

		<div className="transparentContainer">
			<h1 id='onlinejs'>UX/UI Design Online Mentored Bootcamp</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and attendance is confirmed once the payment is made through our online booking. The cost of the course is one-time non-refundable payment which is done upon registration. You'll receive all the materials right after signing up.</p>

				<strong>Cancellation</strong>

				<p>If you need to cancel your enrolment for any reason prior to the course starting date, we will provide you with BCS credit to the refund value for future use towards any other course, class, or workshop. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Withdrawal</strong>

				<p>You may withdraw within the first week of your course. You will be provided with a BCS credit to the refund value for future use towards any other course, class or workshop. Students who withdraw after the Friday at 18:00 CET of the first week of their course are liable for their full course fees. All withdraw requests must be made in writing to office@barcelonacodeschool.com. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Transfers</strong>

				<p>Prior to the start of a bootcamp or during the first week of it, students may be granted one transfer (subject to approval based on class availability and existing circumstances) into a different cohort. No transfers will be granted after Friday at 18:00 CET of the first week of your course.</p>

				<p>All transfer requests must be made in writing to office@barcelonacodeschool.com. If your transfer to the different instance of the same course no transfer fees would be charged. For a transfer to a different course you would be charged the difference in price if there is any.</p>

			</article>
		</div>

		<div id='onlineai' className="transparentContainer">
			<h1>Deep Learning and Artificial Intelligence Engineering Online Mentored Bootcamp</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and attendance is confirmed once the payment is made through our online booking. The tuition cost is paid in the following manner: the registration deposit of 900€ is paid upon online registration. The remaining tuition cost is due in between the registration date and the starting date of the bootcamp but no later than one week before the starting date. </p>

				<strong>Cancellation</strong>

				<p>If you need to cancel your enrollment for any reason 30 days prior to the course starting date, we will provide you with BCS credit to the refund value for future use towards any other course, class, or workshop. This registration deposit cannot be redeemed towards any BCS course or workshop except for changing to the different cohort of the same course. BCS credit is valid for one year from the starting date of the course you signed up for. In case of cancellation in less than 30 days prior to the course starting date we will provide you with 75% of the course tuition in BCS credit to the refund value for future use towards any other course, class, or workshop minus the registration deposit. </p>

				<strong>Withdrawal</strong>

				<p>You may withdraw within the first week of your course. You will be provided with a refund of 50% of the full course tuition in BCS credit to the refund value for future use towards any other course, class or workshop minus the registration deposit. Students who withdraw after the Friday at 18:00 CET of the first week of their course are liable for their full course fees. All withdrawal requests must be made in writing to office@barcelonacodeschool.com. BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<strong>Transfers</strong>

				<p>Prior to the start of a bootcamp or during the first week of it, students may be granted one transfer (subject to approval based on class availability and existing circumstances) into a different cohort. No transfers will be granted after Friday at 18:00 CET of the first week of your course.</p>

				<p>All transfer requests must be made in writing to office@barcelonacodeschool.com. If your transfer to the different instance of the same course no transfer fees would be charged. For a transfer to a different course you would be charged the difference in price if there is any.</p>

			</article>
		</div>

		<div className="transparentContainer">
			<h1 id='shortcourses'>Short workshops and courses</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and acceptance is confirmed once the payment is made through our online booking form.</p>

				<strong>Cancellation</strong>

				<p>The following in-person short courses – "Programming with JavaScript", "Responsive Web Design", "React.js Crash Course", "Back-end Development with Node, Express, MongoDB" – are modules of the JavaScript Full-Stack Bootcamp. While we keep the in-person groups small and the demand is high, we have a strict cancellation policy regarding these courses.</p>

				<p>If canceled by the student more than 30 calendar days in advance you will be refunded with BCS credit to the course full value for future use towards any other short courses or workshops.</p>

				<p>In case of cancellation by student in less than 30 calendar days we will provide a 50% credit to the course full value for future use towards any other short courses or workshops. In case of no show no refund or credits could be offered.</p>

				<p>BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<p>BCS reserves a right to cancel or reschedule any workshop or short course at any moment with notifying the students via email and offering full refund or credit to the full value for future use towards the same or any other workshops or short courses we host.</p>

				<p>All requests to cancel or transfer must be made in writing by email to office@barcelonacodeschool.com.</p>
			</article>
		</div>

		<div className="transparentContainer">
			<h1 id='kids'>BCS Young Coders Summer Camp</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and acceptance is confirmed once the payment is made through our online booking form.</p>

				<strong>Cancellation</strong>

				<p>If you will need to cancel the course before it begins we will provide you with full refund or credit to the refund value for use towards any other week of the summer camp.</p>

				<p>BCS reserves a right to cancel or reschedule any workshop or short course at any moment before it begins with notifying the students and offering full refund or credit to the full value for future use towards same or any other workshops or short courses we host.</p>

				<p>BCS credit is valid for one year from the starting date of the course you signed up for.</p>

				<p>All requests to cancel or transfer must be made in writing by email to office@barcelonacodeschool.com.</p>
			</article>
		</div>

		<div className="transparentContainer">
			<h1>BCS Young Coders After-School Classes</h1>
			<article className='whiteCard'>
				<strong>Reservation</strong>

				<p>Your place is reserved and attendance is confirmed once the payment is made through our online booking. You can sign up and join the course anytime throughout the year and start attending from the next scheduled class after the moment the payment was made.</p>

				<strong>Cancellation</strong>

				<p>If the course is canceled by student you will get a refund proportionally to the unattended classes left in the current month. In case of no show no refunds or changes of groups/courses is possible. Any changes between groups or courses should be requested by email at office@barcelonacodeschool.com and are subject to the availability.</p>

				<p>If any weekly class would be canceled by BCS we will compensate it by setting up an extra class with no charge.</p>
			</article>
		</div>

	</>
)

export default TandC
